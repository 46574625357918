<template>
    <div class="property__box-grid">
        <div class="property__inner" @click="goToProperty()" data-e2e="selectPropertyFromSearchResults">
            <div class="property__info">
                <div class="property__scorestars starsTop"><Stars :hotelRating="hotelRating" /></div>
                <div v-if="index<4" class="property__thumb" :style="'background-image:url('+property.thumbNailUrl+');'"></div>
                <div v-else class="property__thumb" v-lazy:background-image="property.thumbNailUrl">    
                    <div class="spinner__box"><b-spinner variant="primary" /></div>
                </div>
                <div class="boxed">
                    <h5 class="property__name">{{name}}</h5>
                    <div class="property__city">
                        <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.49496 0.000976562C3.3958 0.000976563 2.34169 0.437559 1.56448 1.21478C0.78726 1.99199 0.350586 3.0462 0.350586 4.14535C0.350586 6.35367 4.06588 10.2677 4.22415 10.4335C4.25832 10.4693 4.29927 10.498 4.3446 10.518C4.38993 10.5379 4.43876 10.5488 4.48827 10.5497C4.53779 10.5507 4.587 10.5418 4.63308 10.5237C4.67916 10.5055 4.72119 10.4785 4.75674 10.444L4.76649 10.4335C4.92477 10.2677 8.63932 6.35367 8.63932 4.14535C8.63932 3.0462 8.20265 1.99199 7.42543 1.21478C6.64822 0.437559 5.59411 0.000976562 4.49496 0.000976562ZM4.49496 9.62035C3.34204 8.35792 1.10445 5.60358 1.10445 4.14461C1.1177 3.25403 1.48078 2.40438 2.11525 1.77928C2.74972 1.15418 3.60464 0.803736 4.49532 0.803736C5.386 0.803736 6.24093 1.15418 6.8754 1.77928C7.50987 2.40438 7.87295 3.25403 7.88619 4.14461C7.88619 5.59982 5.64787 8.35792 4.49496 9.62035ZM4.49496 2.26183C4.12246 2.26198 3.75837 2.37251 3.44872 2.57957C3.13908 2.78662 2.89778 3.08091 2.75534 3.42509C2.61289 3.76927 2.57572 4.14794 2.64848 4.51326C2.72124 4.87858 2.90069 5.21411 3.16413 5.47745C3.42758 5.74079 3.76316 5.92008 4.12851 5.99269C4.49385 6.06531 4.87255 6.02796 5.21668 5.88538C5.5608 5.74279 5.8549 5.5014 6.06183 5.19167C6.26876 4.88194 6.37925 4.51784 6.37925 4.14535C6.37925 3.89794 6.33046 3.65292 6.23576 3.42436C6.14106 3.19579 6.00227 2.98815 5.82729 2.81324C5.65231 2.63833 5.4446 2.49956 5.21599 2.40495C4.98739 2.31034 4.74237 2.26173 4.49496 2.26183ZM4.49496 5.27573C4.27159 5.27558 4.05329 5.20918 3.86764 5.085C3.68198 4.96081 3.53729 4.78438 3.45188 4.578C3.36647 4.37161 3.34417 4.14456 3.38778 3.9255C3.4314 3.70643 3.53897 3.5052 3.69691 3.34726C3.85485 3.18932 4.05609 3.08174 4.27515 3.03813C4.49421 2.99452 4.72126 3.01682 4.92765 3.10223C5.13404 3.18763 5.31046 3.33237 5.43465 3.51803C5.55884 3.70368 5.62523 3.92198 5.62538 4.14535C5.62538 4.44515 5.50627 4.73263 5.29428 4.94463C5.08229 5.15662 4.79476 5.27573 4.49496 5.27573Z" fill="#333333"/>
                        </svg>
                        {{city}}
                    </div>
                    <div class="property__room">
                        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.577833 0.0862406C0.504315 0.094122 0.436418 0.129373 0.38765 0.184949C0.338883 0.240524 0.312796 0.312384 0.314535 0.386302V8.05688C0.313441 8.13526 0.343529 8.21092 0.39818 8.26712C0.452832 8.32331 0.527561 8.35548 0.605944 8.35658C0.684326 8.35767 0.759935 8.32753 0.816133 8.27288C0.872332 8.21823 0.904545 8.1435 0.905639 8.06512V6.87696H12.7206V8.05688C12.7195 8.13526 12.7496 8.21092 12.8042 8.26712C12.8589 8.32331 12.9336 8.35548 13.012 8.35658C13.0904 8.35767 13.166 8.32753 13.2222 8.27288C13.2784 8.21823 13.3106 8.1435 13.3116 8.06512V3.63122C13.3124 3.59254 13.3056 3.55407 13.2915 3.51804C13.2775 3.48202 13.2564 3.44913 13.2296 3.42126C13.2027 3.39339 13.1707 3.37116 13.1352 3.3557C13.0997 3.34024 13.0616 3.33184 13.0229 3.33116C13.0099 3.33006 12.9969 3.33006 12.9839 3.33116C12.9104 3.3392 12.8427 3.37451 12.7939 3.43005C12.7452 3.48559 12.719 3.55735 12.7206 3.63122V4.22155H0.905639V0.386302C0.905687 0.308513 0.875508 0.233692 0.82149 0.177715C0.767472 0.121738 0.693804 0.0889633 0.616062 0.0862406C0.60332 0.0855109 0.590574 0.0855109 0.577833 0.0862406ZM2.47934 1.56549C2.2648 1.55941 2.05658 1.63841 1.90005 1.78525C1.74351 1.93209 1.65134 2.1348 1.64371 2.34929V3.33574C1.64371 3.37451 1.65137 3.41291 1.66623 3.44873C1.68109 3.48454 1.70288 3.51703 1.73033 3.54441C1.75778 3.5718 1.79039 3.59347 1.82624 3.60824C1.8621 3.62301 1.90051 3.63059 1.93928 3.63049H5.18873C5.26692 3.63049 5.3419 3.59942 5.39718 3.54414C5.45247 3.48886 5.48353 3.41392 5.48353 3.33574V2.34929C5.47609 2.13487 5.38411 1.93211 5.22769 1.78525C5.07128 1.63839 4.86314 1.55942 4.64867 1.56549H2.47934ZM2.47934 2.15582H4.64867C4.8047 2.15582 4.8932 2.25103 4.8932 2.34929V3.04089H2.23257V2.34929C2.23257 2.25103 2.32332 2.15582 2.47934 2.15582ZM0.904128 4.81114H12.7191V6.2859H0.905639L0.904128 4.81114Z" fill="#333333"/>
                        </svg>
                        <strong>{{room_name}}</strong>
                    </div>
                    <div class="property__link">
                        Vedi tutte le camere...
                    </div>
                    <div class="property__amenities">
                        <span v-for="(value, name) in amenities" v-bind:key="name" :class="'amenity '+(propertyAmenitiesIcons[name] !== undefined ? propertyAmenitiesIcons[name] : '')">
                            {{amenitiesLabels[name].label}}
                        </span>
                    </div>
                </div>
                
            </div>
            <div class="property__rates">
                <span class="property__scoredesc">{{score_description}}</span>
                <span class="property__scoreicon">
                    <svg width="71" height="36" viewBox="0 0 71 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M70.523 35.3587C65.8588 35.3587 61.3856 33.5059 58.0876 30.2078C54.7895 26.9097 52.9367 22.4366 52.9367 17.7724C52.9367 13.1082 51.0838 8.63506 47.7857 5.33699C44.4877 2.03892 40.0145 0.186035 35.3503 0.186035C30.6862 0.186035 26.213 2.03892 22.915 5.33699C19.6169 8.63506 17.764 13.1082 17.764 17.7724C17.764 22.4366 15.9112 26.9097 12.6131 30.2078C9.31506 33.5059 4.84191 35.3587 0.177734 35.3587H70.523Z" fill="#78C94D"/>
                    </svg>
                    <span class="score">{{Number((salabamScore/20).toFixed(1))}}/5</span>
                </span>
                <span class="property__scorestars">
                    <!--<Stars :hotelRating="hotelRating" />-->
                </span>
            </div>

        </div>
        <!--<b-button variant="primary" class="property__cta uppercase" @click="mixinGoTo('property',{propertyId: property_id, newWindow: false})">dimmi di più</b-button>-->
    </div>
</template>

<script>
import Vue from 'vue'
import Stars from '../../atoms/stars'
export default {
    name: 'property',
    props:
    {
        property: Object,
        index: Number
    },
    components:
    {
        Stars
    },
    data(){
        return {
            address1:           this.property.address1,
            amenities:          this.property.amenities,
            availableRooms:     this.property.availableRooms,
            city:               this.property.city,
            countryCode:        this.property.countryCode,
            propertyId:         this.property.propertyId,
            propertyRating:     this.property.propertyRating,
            latitude:           this.property.latitude,
            longitude:          this.property.longitude,
            name:               this.property.name,
            postalCode:         this.property.postalCode,
            propertyAmenities:  this.property.propertyAmenities,
            propertyCategory:   this.property.propertyCategory,
            property_id:        this.property.property_id,
            proximityDistance:  this.property.proximityDistance,
            rate_id:            this.property.rate_id,
            roomIdsWithKitchen: this.property.roomIdsWithKitchen,
            room_id:            this.property.room_id,
            room_name:          this.property.room_name,
            salabamScore:       this.property.salabamScore,
            score_description:  this.property.score_description,
            shortDescription:   this.property.shortDescription,
            thumbNailUrl:       this.property.thumbNailUrl,
            total:              this.property.total,
            hotelRating:        this.property.hotelRating,
            propertyAmenitiesIcons:       Vue.prototype.$config.icons.propertyAmenities,
            amenitiesLabels:       Vue.prototype.$config.amenities
        }
    },
    mounted()
    {
        //window.console.log(Vue.prototype.$config.propertyAmenitiesIcons)
    },
    methods:
    {
        goToProperty()
        {
            this.mixinSendMutation('setScroll', window.pageYOffset)
            this.mixinSendMutation('setPropertyDetail', this.property)
            this.mixinGoTo('property',{
                propertyBand: this.property.band,
                propertyId: this.property_id,
                propertyPad: this.property,
                propertyName: this.property.name,
                newWindow: false
            })

        }        
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .property__box-grid
    {
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        height:100%;
        cursor: pointer;

        .property__inner
        {
            box-shadow:3px 5px 10px 0px rgba(0,0,0,0.15);//$box-shadow;
            border-bottom-left-radius: $border-radius-large;
            border-bottom-right-radius: $border-radius-large;
            overflow:hidden;
            margin-bottom:$grid-gutter-width/2;
            height:100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;            
            position:relative;
            @include media-breakpoint-up(md)
            {
                margin-bottom:$grid-gutter-width;
            }
        }

        .property__thumb
        {
            transition:$transition-fade;
            background-size:cover;
            background-position:center center;
            width:100%;
            //padding-top: 75%;
            position:relative;
            background-color:rgba(0,0,0,0.03);
            border:1px solid rgba(0,0,0,0.125);
            height:200px;
            .spinner__box
            {
                position:absolute;
                top:50%;
                transform:translateY(-50%);
                left:0;
                right:0;
                margin:auto;
                text-align:center;
            }
            img
            {
                display:none;
                width:100%;
                visibility:hidden;
            }            
        }
        .property__info
        {
            .boxed
            {
                padding:$grid-gutter-width/4 $grid-gutter-width/3 0 $grid-gutter-width/4;
            }
        }
        .property__name
        {
            margin:0;
            padding:0;
            font-weight:bold;
            color:$black;
            font-size:18px;
            margin-bottom:8px;

            min-height:42px;
        }
        .property__city
        {
            position:relative;
            padding-left:22px;
            font-size:11px;
            svg
            {
                width:10px;
                position:absolute;
                left:0;
                top:50%;
                transform:translateY(-50%);
            }
        }
        .property__room
        {
            position:relative;
            padding-left:22px;
            font-size:11px;
            svg
            {
                width:18px;
                position:absolute;
                left:0;
                top:50%;
                transform:translateY(-50%);
            }
        }
        .property__link
        {
            padding-left:22px;
            color:$light-blue;
            font-size:11px;
            text-decoration:underline;
        }

        

        .property__rates
        {
            $icon-width:70px;
            padding:0;
            width:90%;
            margin:0 auto;
            margin-bottom:0;
            font-size: 11px !important;
            font-weight: bold !important;
            text-transform:none !important;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            .property__scoredesc
            {
                padding:10px;
                width:calc((100% - #{$icon-width})/2);
                text-align:center;
            }
            .property__scoreicon
            {
                width:$icon-width;
                transform:translateY(1px);
                color:$white;
                text-align:center;
                svg
                {
                    width:100%;
                }
                .score
                {
                    position:absolute;
                    left:0;
                    right:0;
                    text-align:center;
                    top:50%;
                    transform:translateY(-50%);
                }
            }
            .property__scorestars //ora vuoto, serve solo per allineamento flexbox
            {
                padding:0;
                text-align:center;
                width:calc((100% - #{$icon-width})/2);
                svg
                {
                    width:11px;
                    margin-right:2px;
                }
            }
        }



        .property__scorestars.starsTop
        {
            position:absolute;
            top:0.85rem;
            left:0.85rem;
            z-index: 1;
            width:auto;
            .stars
            {
                padding:0 0.65rem;
                border-radius: $border-radius-large;
                background:$white;
                svg
                {
                    width:9px;
                    height:9px;
                    transform:translateY(-2px);
                    margin:0 2px;
                    *
                    {
                        //fill:$gold;
                    }
                }

                span:not(.StarKo) svg *
                {
                    fill:$gold;
                }
            }
        }

        .property__amenities
        {
            margin-top:1rem;
            display:flex;
            flex-wrap:wrap;
            .amenity
            {
                font-weight: normal;
                font-size: 12px;
                width:50%;
                min-width:50%;
                max-width:50%;
                text-align:left;
                margin-bottom:0.45rem;
                padding-right:12px;
                &:before
                {
                    margin-right:4px;
                }
            }
        }
 

        /*
        .property__name
        {
        }
        .property__salabamScore
        {
        }
        .property__score_description
        {
        }
        .property__address1
        {
            line-height: 1;
            padding-top:0.5rem;
            svg
            {
                transform:translateY(-2px)
            }
        }
        .property__shortDescription
        {
            line-height: 1.25;
            opacity:0.5;
        }*/

        //img[lazy=loading] {}
        //img[lazy=error] {}
        //img[lazy=loaded] {}
        //*[lazy=loading] {}
        //*[lazy=error] {}
        *[lazy=loaded] {
            .spinner__box
            {
                display:none;
            }
        }             
    }
</style>